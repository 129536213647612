<template>
  <div class="h-full max-3xl:max-w-full max-3xl:w-screen container mx-auto flex flex-col flex-1">
    <PageHeader />
    <main class="flex flex-grow text-left">
      <CollegeTable />
    </main>
    <footer class="text-right text-sm p-6 3xl:px-0 flex flex-row justify-between items-center justify-center">


      <span>
        &copy; 2023 DegreeData, LLC
      </span>
      <a href="https://degreedata.com/privacy-policy/" class="underline text-sm">
        Privacy Policy
      </a>
    </footer>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  }
};
</script>

<style>
html,
body,
#app {
  min-height: 100vh;
}


#app {
  font-family: "Inter var", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 14px;

  display: flex;
  flex-direction: column;
}
</style>
